import {
  ref, onMounted, getCurrentInstance
} from 'vue'
import { startScan, prepare, stopScan } from '@/plugins/barcodeScanner'
import QrScanner from 'qr-scanner'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Flashlight } from '@ionic-native/flashlight'

export default {
  setup() {
    const app = getCurrentInstance()
    const { $toast } = app!.appContext.config.globalProperties
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const result = ref(null)
    const onScan = ref(false)
    const videoScan = ref()
    let qrScanner = null as any

    const checkFlash = async () => {
      const test = await Flashlight.available()
      Flashlight.switchOn()
      console.log('test flash', test)
    }

    const barcodeMobile = async () => {
      await prepare()
      document.body.style.background = 'transparent'
      onScan.value = true
      const value = await startScan()
      if (value) {
        result.value = value
        onScan.value = false
        await stopScan()
        if (route.name === 'barcode-pengecekan-resi') {
          router.push({ name: 'receipt-checking-params', params: { id: value } })
        } else {
          router.push({ name: 'transaksi-barang-diterima-detail', params: { id: value } })
        }
      } else {
        $toast.add({
          severity: 'error', detail: 'qrcode tidak ditemukan', group: 'bc', life: 3000
        })
      }
    }

    const barcodeWeb = async () => {
      qrScanner = new QrScanner(videoScan.value, (decode: any) => {
        console.log('result barcode', decode)
        qrScanner.stop()
      })

      const checkCamera = await QrScanner.hasCamera()
      if (checkCamera) {
        console.log('on checked camera')
        qrScanner.start()
      } else {
        $toast.add({
          severity: 'error', detail: 'Camera tidak ditemukan/didukung', group: 'bc', life: 3000
        })
      }
    }

    const startBarcode = () => {
      if (store.state.device.isMobile) {
        barcodeMobile()
      } else {
        barcodeWeb()
      }
    }

    onMounted(() => {
      console.log('masukk hal barcode')
      startBarcode()
    })
    return {
      result,
      videoScan,
      onScan,
      checkFlash,
      startBarcode,
      barcodeMobile,
    }
  }
}
